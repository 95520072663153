.admin {
  padding-top: 2rem;
    .menuicon {
      padding-left: 2rem;
      padding-bottom: 3rem;
      display: flex;
      align-items: center;
      .title {
        padding-left: 2rem;
        img {
          width: 150px;
        }
      }
      .togglemenuicon {
        cursor: pointer;
      }
    }
    .main {
      display: flex;
      .navbar {
        width: 25%;
        min-height: 80vh;
      }
      .navbarhide {
        display: none;
      }
      .content {
        width: 75%;
        padding-left: 2rem;
        padding-top: 2rem;
        @media screen and (max-width: 800px) {
          width: 100%;
        }
      }
    }

  }